<script setup lang="ts">
import { CmsBlock, CmsSlot } from "@shopware-pwa/types";

type CmsBlockMaasAccordion = CmsBlock & {
  slots: Array<
    CmsSlot & {
      slot: "maasAccordion";
    }
  >;
};

const props = defineProps<{
  content: CmsBlockMaasAccordion;
}>();

const { getSlotContent } = useCmsBlock(props.content);
const slot: any = getSlotContent("maasAccordion");

const { getConfigValue } = useCmsElementConfig(slot);

const textStyle: string = getConfigValue("textStyle");
const items: any = getConfigValue("objItems");
</script>

<template>
  <div class="s-gutter">
    <div class="c-width-small">
      <SharedAccordion
        :title="item.title" v-for="item in items"
        :class="{
          'font-serif': textStyle === 'magazine'
        }"
      >
        <div class="flex gap-y-6" :class="item.mediaPosition === 'below' ? 'flex-col-reverse justify-end' : 'flex-col'">
          <SharedCmsImage
            v-if="item?.media?.id"
            :src="item?.media?.url"
            :crop="item?.media?.crop"
            :focus-point="item?.media?.focusPoint"
            :alt="item.caption"
            :width="884"
            :class-img="{
              'aspect-square': item?.aspectRatio === '1x1',
              'aspect-video': item?.aspectRatio === '16x9',
              'aspect-2/3': item?.aspectRatio === '2x3',
            }"
          />
          <div v-html="item.text" class="richtext" :class="textStyle"></div>
        </div>
      </SharedAccordion>
    </div>
  </div>
</template>
